import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import { fonts } from './styles';

const styles = StyleSheet.create({
  card: {
    width: 350,
    minWidth: 350,
    border: '1px LightGray solid',
    textDecoration: 'none',
    color: 'black',

    ':hover': {
      border: '1px black solid',
    },
  },

  image: {
    height: 200,
    width: '100%',
    borderBottom: '1px LightGray solid',
  },

  content: {
    padding: 20,
  },

  header: {
    fontFamily: fonts.hoeflerText,
    fontSize: 18,
    marginBottom: 10,
  },

  description: {
    fontFamily: fonts.hoeflerText,
    color: 'DimGrey',
  },
});

export default function Card({ name, imageSrc, description, url }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className={css(styles.card)}
    >
      <img className={css(styles.image)} src={imageSrc} alt="" />
      <div className={css(styles.content)}>
        <h2 className={css(styles.header)}>{name}</h2>
        <p className={css(styles.description)}>{description}</p>
      </div>
    </a>
  );
}
