// @flow strict
export const hoeflerText = [
  'Hoefler Text',
  'Baskerville Old Face',
  'Garamond',
  'Times New Roman',
  'serif',
];

export const andaleMono = ['Andale Mono', 'AndaleMono', 'monospace'];

export const verdana = ['Verdana', 'Geneva', 'sans-serif'];
