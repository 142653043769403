import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';

import { fonts } from './styles';
import Card from './Card';

const styles = StyleSheet.create({
  app: {
    width: '100%',
  },

  header: {
    display: 'grid',
    gridTemplateAreas: '"mast"',
    height: 300,
    alignItems: 'end',
  },

  masthead: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    gridArea: 'mast',
    width: '100%',
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 20,

    fontFamily: fonts.andaleMono,
  },

  profile: {
    width: 160,
    height: 160,
    background: 'white',
    border: '2px LightGray solid',
  },

  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 10,
  },

  link: {
    textDecoration: 'none',
    color: 'black',

    ':hover': {
      textDecoration: 'underline',
    },
  },

  background: {
    gridArea: 'mast',
    width: '100%',
  },

  image: {
    zIndex: 0,
    opacity: 0.3,
    width: '100%',
    height: 300,
    overflow: 'hidden',
  },

  cards: {
    margin: '30px 0',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyContent: 'center',
    justifyItems: 'center',
    gridRowGap: 20,

    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
      justifyContent: 'center',
      justifyItems: 'center',
    },
  },

  footer: {
    gridArea: 'foot',
    textAlign: 'center',
    width: '100%',
    margin: '20px 0',
    fontFamily: fonts.hoeflerText,
    color: 'DimGrey',
  },
});

function ShareLink({ url, text }) {
  return (
    <a
      className={css(styles.link)}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      {text}
    </a>
  );
}

export default function App() {
  return (
    <div className={css(styles.app)}>
      <header className={css(styles.header)}>
        <div className={css(styles.masthead)}>
          <img
            className={css(styles.profile)}
            src={require('./images/profile.jpg')}
            alt=""
          />
          <h1 className={css(styles.title)}>@tesseralis</h1>
          <p>
            <ShareLink text="Blog" url="https://blog.tessera.li" /> |{' '}
            <ShareLink text="Ko-fi" url="https://ko-fi.com/tesseralis" /> |{' '}
            <ShareLink text="Github" url="https://github.com/tesseralis" /> |{' '}
            <ShareLink text="Twitter" url="https://twitter.com/tesseralis" /> |{' '}
            <ShareLink
              text="Instagram"
              url="https://instagram.com/tesseralis"
            />{' '}
            |{' '}
            <ShareLink text="Tumblr" url="https://tumblr.com/blog/tesseralis" />
          </p>
        </div>
        <div className={css(styles.background)}>
          <img
            className={css(styles.image)}
            src={require('./images/masthead.png')}
            alt=""
          />
        </div>
      </header>
      <main className={css(styles.cards)}>
        <Card
          name="Minnows"
          imageSrc={require('./images/minnows.png')}
          description="See how polyominoes evolve from a single square."
          url="https://tesseralis.github.io/minnows"
        />
        <Card
          name="Polyhedra Viewer"
          imageSrc={require('./images/polyhedra.png')}
          description="Explore the relationships between convex regular-faced polyhedra."
          url="https://polyhedra.tessera.li"
        />
        <Card
          name="Oru"
          imageSrc={require('./images/oru.png')}
          description="A puzzle game about origami animals."
          url="https://tesseralis.github.io/Oru"
        />
      </main>
      <footer className={css(styles.footer)}>
        Copyright © 2018 Nat Alison
      </footer>
    </div>
  );
}
